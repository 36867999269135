import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../utils/siteVars';
import BtnNoStyle from './BtnNoStyle';
import LinkPreventWrapper from './LinkPreventWrapper';

const StyledBreadcrumbs = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    background: ${colors.mediumGrey};
    height: 1px;
  }
  .breadcrumbs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .breadcrumbs-left {
      display: flex;
      flex-wrap: wrap;
      span {
        margin-right: 0.3rem;
        display: flex;
        align-items: center;
        p,
        a {
          white-space: nowrap;
          margin-right: 0.3rem;
          font-size: 1rem;
          color: ${colors.lightBlue};
          font-weight: 400;
        }
        p {
          color: ${colors.mediumGrey};
        }
        a {
          transition: 100ms ease-in-out;
          &:hover {
            color: ${colors.lightBlueFaded};
          }
        }
        img {
          height: 20px;
          object-fit: contain;
        }
      }
    }
  }
  .breadcrumbs-right {
    button {
      margin-left: 0.5rem;
      white-space: nowrap;
    }
  }
  .breadcrumbs-right-mobile-outer {
    display: none;
  }
  @media (max-width: 1350px) {
    .breadcrumbs-right-mobile-outer {
      display: block;
      position: relative;
      img {
        width: 1.5rem;
      }
      .breadcrumbs-right-mobile-inner {
        position: absolute;
        right: 1rem;
        top: 1rem;
        background: ${colors.white};
        padding: 0.5rem;
        z-index: 2;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
        transition: 100ms ease-in-out;
        opacity: 0;
        pointer-events: none;
        &.active {
          pointer-events: initial;
          opacity: 1;
        }
        button,
        a {
          width: 100%;
          text-align: right;
          white-space: nowrap;
        }
      }
    }
    .breadcrumbs-right {
      display: none;
    }
  }
  @media (max-width: 1000px) {
    .breadcrumbs-container {
      .breadcrumbs-left {
        margin-bottom: 0.5rem;
      }
      .breadcrumbs-right-mobile-outer {
      }
    }
  }
`;

const Breadcrumbs = (props) => {
  const { crumbs } = props;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <StyledBreadcrumbs>
      <div className="breadcrumbs-container" data-test="breadcrumbs">
        <div className="breadcrumbs-left">
          {crumbs
            ? crumbs.map((crumb, index) => (
                <React.Fragment
                  key={crumb.p ? crumb.p + index : 'crumb' + index}
                >
                  {crumb.p ? (
                    <span>
                      {index === crumbs.length - 1 ? (
                        <p>{crumb.p}</p>
                      ) : (
                        <LinkPreventWrapper to={crumb.to}>
                          {crumb.p}
                        </LinkPreventWrapper>
                      )}
                      {index !== crumbs.length - 1 ? (
                        <img
                          src={`/icons/${
                            index === crumbs.length - 2
                              ? 'arrow-right-grey.png'
                              : 'arrow-right-blue.png'
                          }`}
                          alt=""
                        />
                      ) : null}
                    </span>
                  ) : null}
                </React.Fragment>
              ))
            : null}
        </div>
        <div className="breadcrumbs-right">{props.rightContent}</div>
        {props.rightContent ? (
          <div className="breadcrumbs-right-mobile-outer">
            <BtnNoStyle
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              data-test="breadcrumb-menu"
            >
              <img src="/icons/3-dots.png" alt="" />
            </BtnNoStyle>
            <div
              className={`breadcrumbs-right-mobile-inner ${
                mobileMenuOpen ? 'active' : ''
              }`}
            >
              {props.rightContent}
            </div>
          </div>
        ) : null}
      </div>
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
