import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Layout from '../../components/Layout';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { parseQuery } from '../../utils/parseQuery';
import { do_url } from '../../utils/siteVars';
import Breadcrumbs from '../../components/Breadcrumbs';
import Card from '../../components/Card';
import Button from '../../components/Button';
import OverlayBox from '../../components/OverlayBox';

const StyledAddToCustomerPortalPage = styled.div`
  .action-buttons {
    display: flex;
    gap: 0.5rem;
  }
  code {
    max-width: 300px;
    width: 100%;
    display: block;
    margin-top: 1rem;
    white-space: nowrap;
  }
`;

const createEmailContent = [
  'Hej',
  '',
  'H2O Teknik Mellansverige AB har nyligen utfört underhåll i era dricksvattenreservoarer och har dokumentation i form av digitala filer att dela med er.',
  '',
  'Via länken i detta mail kan du som representant för beställaren skapa ett lösenord till vår kundportal. Därefter kan du med hjälp av din e-post och valda lösenordet logga in och ladda ner filerna. Denna länk för att skapa lösenord är giltig i 14 dagar',
];

const loginEmailContent = [
  'Hej',
  '',
  'H2O Teknik Mellansverige AB har nyligen utfört underhåll i era dricksvattenreservoarer och har dokumentation i form av digitala filer att dela med er.',
  '',
  'Via länken i detta mail kan du som representant för beställaren logga in med din personliga profil för att ladda ner filerna.',
];

const AddToCustomerPortalPage = ({ location }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [popupOpen, setPopupOpen] = useState(false);
  const [portalCustomerUsers, setPortalCustomerUsers] = useState([]);
  const [portalCustomer, setPortalCustomer] = useState([]);
  const [customer, setCustomer] = useState();
  const [generatedLink, setGeneratedLink] = useState();
  const [messageType, setMessageType] = useState('create');
  const [data, setData] = useState({
    contactPersonEmail: '',
    message: createEmailContent,
  });

  const copyLink = () => {
    navigator.clipboard.writeText(generatedLink.link);
    dispatch({ type: 'ALERT', content: 'Länken kopierades' });
  };

  const getCustomer = async () => {
    try {
      const { id } = parseQuery(location);
      const { data } = await axios.get(
        `${process.env.GATSBY_BACKEND_API_URL}portalCustomers/${id}`,
        {
          headers: { Authorization: 'Bearer ' + state.token },
        }
      );
      setCustomer(data.customer);
      if (data.portalCustomerUsers?.length) {
        setPortalCustomerUsers(data.portalCustomerUsers);
      }
      setPortalCustomer(data.portalCustomer);
    } catch (err) {
      console.log(err.response);
      dispatch({ type: 'ALERT', content: 'Något gick fel.' });
    } finally {
      dispatch({ type: 'LOADING', value: false });
    }
  };

  useEffect(() => {
    setData({
      ...data,
      message:
        messageType === 'create' ? createEmailContent : loginEmailContent,
    });
    //eslint-disable-next-line
  }, [messageType, setData]);

  const createCustomerFolder = async () => {
    try {
      const { id } = parseQuery(location);
      const url = `${do_url}admin-side-portal-customer-files/${state.token}/add-customer-folder`;
      const body = {
        customerId: id,
      };
      await axios.post(url, body);
      console.log('Kundmapp skapad i kundportalen');
    } catch (err) {
      console.log(err.response);
      return err;
    }
  };

  const initSend = () => {
    setPopupOpen(true);
  };

  const sendEmail = async (generateLinkOnly) => {
    dispatch({ type: 'LOADING', value: true });
    try {
      const body = {
        generateLinkOnly,
        needsNewPassword: messageType === 'create',
        message: data.message,
        portalCustomer: {
          loginEmail: data.contactPersonEmail,
          customerId: customer._id,
          customerName: customer.customerInformation.name,
        },
      };
      const url = `${do_url}admin-side-portal-customer-files/${state.token}/send-password-creation-email`;
      const { data: response } = await axios.post(url, body);

      const createError = await createCustomerFolder();
      if (createError) throw createError;

      if (typeof response?.link === 'string') {
        setGeneratedLink(response);
        dispatch({ type: 'ALERT', content: 'Länken genererades.' });
      } else {
        const { id } = parseQuery(location);
        dispatch({ type: 'ALERT', content: 'Mailet skickades.' });
        navigate(`/customers/customer-portal/?id=${id}`);
      }

      setPopupOpen(false);
    } catch (err) {
      console.error(err?.response || err);
      dispatch({ type: 'ALERT', content: 'Något gick fel' });
    } finally {
      dispatch({ type: 'LOADING', value: false });
    }
  };

  useEffect(() => {
    dispatch({ type: 'LOADING', value: true });
    if (state.token) {
      getCustomer();
    }
    // eslint-disable-next-line
  }, [state.token]);

  return (
    <Layout>
      {customer?.customerInformation ? (
        <StyledAddToCustomerPortalPage>
          <h1 className="page-header">
            Skicka mail till {customer?.customerInformation?.name}
          </h1>
          <Breadcrumbs
            crumbs={[
              { to: '/home', p: 'Hem' },
              { to: '/customers', p: 'Kunder' },
              {
                to: `/customers/single/?id=${customer?._id}`,
                p: customer?.customerInformation?.name,
              },
              {
                to: `/customers/customer-portal/?id=${customer?._id}`,
                p: 'Kundportal',
              },
              { p: 'Skicka mail' },
            ]}
          />
          <div className="single-customer-inner">
            <div className="single-customer-left-content">
              <Card>
                <div className="customer-card-header">
                  <h3>Skicka mail</h3>
                </div>
                <p>Välj kontaktperson</p>
                {/* eslint-disable-next-line */}
                <select
                  value={data.contactPersonEmail}
                  onChange={(e) => {
                    setData({ ...data, contactPersonEmail: e.target.value });
                  }}
                >
                  <option disabled value="">
                    Välj person
                  </option>
                  {customer.contactPersons.map((person) => (
                    <option value={person.email} key={person.name}>
                      {person.name}
                    </option>
                  ))}
                </select>
                {data.contactPersonEmail ? (
                  <p>Mailet skickas till: {data.contactPersonEmail}</p>
                ) : null}
                <br />
                <p>Välj meddelandetyp</p>
                {/* eslint-disable-next-line */}
                <select
                  value={messageType}
                  onChange={(e) => setMessageType(e.target.value)}
                >
                  <option value="create">Skapa lösenord</option>
                  <option value="login">Logga in</option>
                </select>
                <br />
                <p>
                  E-postmeddelande (
                  {messageType === 'create'
                    ? 'Lösenordslänken'
                    : 'Inloggninglänken'}{' '}
                  läggs till i slutet av meddelandet)
                </p>
                <textarea
                  value={data.message.join('\n')}
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value.split('\n') })
                  }
                />
                <div className="action-buttons">
                  <Button
                    small
                    onClick={initSend}
                    disabled={!data.contactPersonEmail}
                  >
                    Skicka e-post
                  </Button>
                  <Button
                    noBg
                    onClick={() => sendEmail(true)}
                    disabled={!data.contactPersonEmail}
                  >
                    Generera länk
                  </Button>
                </div>
              </Card>
            </div>
            <div className="single-customer-right-content">
              <Card>
                <div className="customer-card-header">
                  <h3>Kontaktpersoner med tillgång</h3>
                </div>
                {portalCustomer?.loginEmail && (
                  <p>{portalCustomer?.loginEmail}</p>
                )}
                {portalCustomerUsers.map((user) => (
                  <p key={user._id}>{user.email}</p>
                ))}
              </Card>
            </div>
          </div>
          <OverlayBox
            header="Bekräfta"
            active={popupOpen}
            close={() => setPopupOpen(false)}
          >
            <p>
              Bekräfta att e-postadressen är korrekt:
              <br />
              {data.contactPersonEmail}
            </p>
            <Button onClick={() => setPopupOpen(false)} med look="danger">
              Avbryt
            </Button>
            <Button onClick={() => sendEmail()} med>
              Skicka e-post
            </Button>
          </OverlayBox>
          {!!generatedLink && (
            <OverlayBox
              active={generatedLink}
              close={() => setGeneratedLink('')}
              header="Länk"
            >
              <p>Länk genererad av typen: {generatedLink.linkType}.</p>
              <code>{generatedLink.link}</code>

              <Button onClick={copyLink} small>
                Kopiera
              </Button>
              <Button onClick={() => setGeneratedLink('')} small look="danger">
                Stäng
              </Button>
            </OverlayBox>
          )}
        </StyledAddToCustomerPortalPage>
      ) : null}
    </Layout>
  );
};

export default AddToCustomerPortalPage;
